import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";

const config = [
  {
    name: "Aboriginal Artwork",
    moduleComponentName: "AboriginalArtworkBanner",
    rowProps: {},
    moduleProps: { encapsulate: true },
  },
  {
    name: "Resources",
    rowProps: { noGutters: true },
    moduleComponentName: "DigitalEISPage",
    moduleProps: {
      content: {
        title: "Skip to Section",
        sectionHeadingProps: {
          heading: {
            priority: 2,
            style: {
              fontWeight: "bold",
              width: "100%",
            },
            text: "Submissions",
          },
          separator: {
            style: {
              width: "100%",
            },
          },
          subheading: {
            text: (
              <div>
                <p>
                  On this page, you can read the submissions that were received
                  on the draft EIS. A total of 8,477 submissions were received.
                </p>
                <p>
                  The response to issues raised in submissions is set out in the{" "}
                  <strong>Submissions Report</strong>{" "}
                  <a href="/">
                    <u>here</u>
                  </a>
                  .
                </p>
                <p>
                  Submissions have been published in two batches. The first
                  batch was published in April 2024 and includes submissions
                  from local councils, Members of Parliament, government bodies,
                  airport operators, general aviation, businesses and special
                  interest and community groups. It also includes form
                  submissions.
                </p>
                <p>
                  The second batch, comprising non-form submissions from
                  individuals and additional submissions from organisations,
                  were published in July 2024, following redaction of personal
                  information.
                </p>
                <p>Confidential submissions have not been published.</p>
              </div>
            ),
          },
          showClosedSubmissionAlert: false,
        },
        topics: [
          {
            id: "airport-operators-section",
            title: "Airport Operators",
            scrollToTop: false,
            content: {
              elementId: "airport-operators-submissions",
              hideContent: false,
            },
          },
          {
            id: "form-submissions-section",
            title: "Form Submissions",
            scrollToTop: false,
            content: {
              elementId: "form-submissions-submissions",
              hideContent: false,
            },
          },
          {
            id: "general-aviation-section",
            title: "General Aviation",
            scrollToTop: false,
            content: {
              elementId: "general-aviation-submissions",
              hideContent: false,
            },
          },
          {
            id: "government-bodies-section",
            title: "Government Bodies",
            scrollToTop: false,
            content: {
              elementId: "government-bodies-submissions",
              hideContent: false,
            },
          },
          {
            id: "individuals-submissions-section",
            title: "Individuals",
            scrollToTop: false,
            content: {
              elementId: "individuals-submissions",
              hideContent: false,
            },
          },
          {
            id: "industry-and-businesses-section",
            title: "Industry and Businesses",
            scrollToTop: false,
            content: {
              elementId: "industry-and-businesses-submissions",
              hideContent: false,
            },
          },
          {
            id: "local-councils-section",
            title: "Councils and Council Organisations",
            scrollToTop: false,
            content: {
              elementId: "local-councils-submissions",
              hideContent: false,
            },
          },
          {
            id: "members-of-parliament-section",
            title: "Members of Parliament",
            scrollToTop: false,
            content: {
              elementId: "members-of-parliament-submissions",
              hideContent: false,
            },
          },
          {
            id: "special-interest-and-community-groups-section",
            title: "Special Interest and Community Groups",
            scrollToTop: false,
            content: {
              elementId: "special-interest-and-community-groups-submissions",
              hideContent: false,
            },
          },
        ],
      },
    },
  },
];

const ResourcesPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default ResourcesPage;
